import React from 'react'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import favicon from '../images/favicon.ico'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import config from '../utils/siteConfig'
import Menu from '../components/Menu'
import Footer from '../components/Footer'

/*
<ThemeProvider theme={theme}>
  <Fragment>
    <Helmet title={data.site.siteMetadata.title} />
    <GlobalStyle />
    <App>
      {children}
    </App>
  </Fragment>
</ThemeProvider>
*/

const Template = ({ children }) => {
  return (
    <div className="siteRoot">
      {/* <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="stylesheet" href="https://use.typekit.net/lic6oky.css" />
        <link rel="icon" href={favicon} />
      </Helmet> */}

      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <div className="siteContent">
            <Menu />
            {children}
          </div>
          <Footer />
        </>
      </ThemeProvider>
    </div>
  )
}

export default Template
